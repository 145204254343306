:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.va .btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.va .btn:hover {
  background: var(--HoverColor);
}
.va .va-paymentMaxWidth {
  max-width: 500px;
  margin: 0 auto;
}
.va .va-paymentMaxWidth .va-detail {
  margin-top: 2rem;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--greyBg);
}
.va .va-paymentMaxWidth .va-detail .btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.va .va-paymentMaxWidth .va-detail .btn:hover {
  background: var(--HoverColor);
}
.va .va-paymentMaxWidth .va-detail .no-va {
  margin: 1rem;
}
.va .va-paymentMaxWidth .va-detail span {
  font-size: 20px;
  padding: 1rem;
}
.va .va-paymentMaxWidth .va-detail img {
  width: 5rem;
}/*# sourceMappingURL=vaticket.css.map */