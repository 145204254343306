:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.header {
  height: 10vh;
  background: linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)),
    url("../../assets/home-picture.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.profile{
    .btn {
        margin-top: 1rem;
        width: 100%;
        padding: 0.6rem 1.5rem;
        color: var(--whiteColor);
        font-weight: 600;
      }
      .btn:hover {
        background: var(--HoverColor);
      }

    .detailProfileMaxWidth{
        max-width: 800px;
        margin: 0 auto;
        .profile-detail{

            margin: 5px 0 5px 0;
            border: 1px solid var(--greyText);
            border-radius: 10px;
            font-size: 18px;
            padding: 10px;
            font-weight: 500;
            .detail-info{
                margin: 1rem;
            }
        }

        .activity{
            padding: 10px;
            .activity-item{
                border-radius: 1rem;
                padding: 1rem;
                background-color: var(--greyBg);
                margin: 10px;
                .inline-flex{
                    // display: inline-flex;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}
