:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.summary .btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.summary .btn:hover {
  background: var(--HoverColor);
}
.summary .detailSummaryMaxwidth {
  max-width: 500px;
  margin: 0 auto;
}
.summary .detailSummaryMaxwidth .detailSummary {
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--greyBg);
}
.summary .detailSummaryMaxwidth .detailSummary .icon {
  font-size: 1rem;
}
.summary .detailSummaryMaxwidth .detailSummary span {
  font-weight: 600;
}
.summary .detailSummaryMaxwidth .detailSummary p {
  margin: 0;
}
.summary .detailSummaryMaxwidth .bookerInfo {
  margin-top: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--greyBg);
}
.summary .detailSummaryMaxwidth .bookerInfo input {
  width: 100%;
  background: var(--whiteColor);
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0 5px 0;
}
.summary .detailSummaryMaxwidth .bookerInfo input::-moz-placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.summary .detailSummaryMaxwidth .bookerInfo input::placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.summary .detailSummaryMaxwidth .bookerInfo input:focus {
  outline: none;
}
.summary .detailSummaryMaxwidth .icon {
  font-size: 1rem;
}
.summary .detailSummaryMaxwidth span {
  font-weight: 600;
}
.summary .detailSummaryMaxwidth p {
  margin: 0;
}
.summary .p-note {
  margin: 0;
  font-size: 12px;
}/*# sourceMappingURL=summary.css.map */