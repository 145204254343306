:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.ticketPayment {
  .btn {
    margin-top: 1rem;
    width: 100%;
    padding: 0.6rem 1.5rem;
    color: var(--whiteColor);
    font-weight: 600;
  }
  .btn:hover {
    background: var(--HoverColor);
  }
  .ticketPayMaxWidth {
    max-width: 500px;
    margin: 0 auto;
    .summaryTicket-detail {
      margin-top: 0;
      margin-bottom:1rem;
      border-radius: 1rem;
      padding: 1rem;
      background-color: var(--greyBg);
      .detailPayment {
        border-radius: 1rem;
        padding: 1rem;
        background-color: var(--greyBg);

        .icon {
          font-size: 1rem;
        }
        span {
          font-weight: 600;
        }
        p {
          margin: 0;
        }
      }
    }
    .paymentTicket-detail {
      margin-top: 0;
      border-radius: 1rem;
      padding: 1rem;
      background-color: var(--greyBg);
      .va {
        margin: 1rem 0 1rem 0;
        .btn {
          margin-top: 1rem;
          width: 100%;
          padding: 0.6rem 1.5rem;
          color: var(--whiteColor);
          font-weight: 600;
        }
        .btn:hover {
          background: var(--HoverColor);
        }
        .bank {
          background-color: var(--whiteColor);
          margin: 5px 0 5px 0;
          border: 1px solid var(--greyText);
          border-radius: 10px;
          font-size: 18px;
          padding: 10px;
          font-weight: 500;
          .labelva {
            margin-right: 10px;
          }
          img {
            width: 3rem;
            margin: 5px 10px 0 0;
          }
        }
        p {
          text-align: center;
        }
      }
      .ticketCounter {
        display: flex;
        justify-content: space-evenly;
        .ticket {
          font-size: 40px;
          margin-top: 0.5rem;
        }

        .btnIncrement,
        .btnDecreament {
          // height: 50px;
          // width: 120px;
          // position: relative;
          padding: 0 40px;
          border-radius: 10px;
          font-size: 40px;
          background-color: var(--PrimaryColor);
          border: 2px solid #2e8d46;
          color: white;
          margin: 20px;
        }
      }
      .no-va {
        margin: 1rem;
      }
      span {
        font-size: 20px;
        padding: 1rem;
      }
      img {
        width: 5rem;
      }
    }
  }
}
