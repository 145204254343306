/* colors */
:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.blog {
  .mainContainer {
    gap: 1rem;
    .singlePost {
      gap: 2rem;

      .imgDiv {
        height: 200px;
        width: 100%;
        border-radius: 1rem;
        border: 5px solid var(--greyBg);
        box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.406);
        overflow: hidden;

        &:hover {
          img {
            transform: scale(1.1);
            transition: 3s ease;
          }
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .postDetails {
        h3 {
          color: var(--blackColor);
          font-weight: 600;
          padding-bottom: 10px;
          font-size: 20px;
        }
        p {
          font-size: 13px;
          color: var(--textColor);
          font-weight: 500;
        }
      }
      a {
        font-size: 15px;
        color: var(--PrimaryColor);
        font-weight: 600;
        padding-top: 0.5rem;
        width: max-content;
        &:hover {
          color: var(--HoverColor);
          font-weight: 600;
          .icon {
            transform: translateX(10px);
          }
        }
      }
    }
  }
}

//media queries

@media screen and (min-width: 884px){
  .blog{
    .mainContainer{
      grid-template-columns: repeat(2,1fr);
    }
  }
}