:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.payment .btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.payment .btn:hover {
  background: var(--HoverColor);
}
.payment .detailPaymentMaxwidth {
  max-width: 500px;
  margin: 0 auto;
}
.payment .detailPaymentMaxwidth .va {
  margin: 1rem 0 1rem 0;
}
.payment .detailPaymentMaxwidth .va .btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.payment .detailPaymentMaxwidth .va .btn:hover {
  background: var(--HoverColor);
}
.payment .detailPaymentMaxwidth .va .bank {
  margin: 5px 0 5px 0;
  border: 1px solid var(--greyText);
  border-radius: 10px;
  font-size: 18px;
  padding: 10px;
  font-weight: 500;
}
.payment .detailPaymentMaxwidth .va .bank .labelva {
  margin-right: 10px;
}
.payment .detailPaymentMaxwidth .va .bank img {
  width: 3rem;
  margin: 5px 10px 0 0;
}
.payment .detailPaymentMaxwidth .va p {
  text-align: center;
}
.payment .detailPaymentMaxwidth .detailPayment {
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--greyBg);
}
.payment .detailPaymentMaxwidth .detailPayment .icon {
  font-size: 1rem;
}
.payment .detailPaymentMaxwidth .detailPayment span {
  font-weight: 600;
}
.payment .detailPaymentMaxwidth .detailPayment p {
  margin: 0;
}
.payment .detailPaymentMaxwidth .bookerInfo {
  margin-top: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--greyBg);
}
.payment .detailPaymentMaxwidth .bookerInfo input {
  width: 100%;
  background: var(--whiteColor);
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0 5px 0;
}
.payment .detailPaymentMaxwidth .bookerInfo input::-moz-placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.payment .detailPaymentMaxwidth .bookerInfo input::placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.payment .detailPaymentMaxwidth .bookerInfo input:focus {
  outline: none;
}
.payment .detailPaymentMaxwidth .icon {
  font-size: 1rem;
}
.payment .detailPaymentMaxwidth span {
  font-weight: 600;
}
.payment .detailPaymentMaxwidth p {
  margin: 0;
}
.payment .p-note {
  margin: 0;
  font-size: 12px;
}/*# sourceMappingURL=payment.css.map */