:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}
.header {
  height: 10vh;
  background: linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)),
    url("../../assets/home-picture.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}
.detailPackage {
  .mainContent {
    .singleDestination {
      overflow: hidden;
      border-radius: 1rem;
      box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);

      .destImage {
        height: 200px;
        width: 100%;
        position: relative;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .detailBookingPr{
      background-color: var(--greyBg);
      border-radius: 1rem;
      .detailBooking {
       
        
        padding: 0.7rem;
  
        .night {
          margin: 0.5rem;
  
          .inline {
            display: inline-flex;
          }
        }
  
        .person {
          margin: 0.5rem;
          .inline {
            display: inline-flex;
          }
        }
        .checkin {
          margin: 0.5rem;
        }
        .icon {
          font-size: 1.5rem;
        }
      }
    }
    

    .btn {
      width: 100%;
      padding: 0.6rem 1.5rem;
      color: var(--whiteColor);
      font-weight: 600;
      margin: 1rem 0 1rem 0;
    }
    .btn:hover {
      background: var(--HoverColor);
    }
    .secContainer {
      margin: 1rem 0rem 1rem 0rem;

      .secHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        .textDiv {
          .secTitle {
            color: var(--blackColor);
            line-height: 1.5rem;
            margin-bottom: 0;
            // padding-bottom: 0.5rem;
            font-weight: 600;
          }
        }
      }

      .title {
        color: var(--blackColor);
        line-height: 1.5rem;
        padding-bottom: 2rem;
        text-align: center;
        font-size: 2rem;
      }
    }

    .facilities {
      margin: 1rem 0 1rem 0;
      font-size: 18px;
    }

    padding: 0.5rem 0;
    gap: 1rem;

    h3 {
      margin: 0;
    }
    p {
      text-align: justify;
      margin-bottom: 0;
      font-size: 18px;
    }
    li {
      list-style-type: circle;
    }
  }
}

@media screen and (min-width: 768px) {
  .detailPackage {
    .mainContent {
      .singleDestination {
        overflow: hidden;
        border-radius: 1rem;
        box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);

        .destImage {
          height: 200px;
          width: 100%;
          position: relative;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .detailBookingPr{
        background-color: var(--greyBg);
        border-radius: 1rem;

        .detailBooking {
          // background-color: var(--greyBg);
          max-width: 40rem;
          padding: 0.7rem;
          position: relative;
          margin: 0 auto;
  
          .night {
            margin: 0.5rem;
  
            .inline {
              display: inline-flex;
            }
          }
  
          .person {
            margin: 0.5rem;
            .inline {
              display: inline-flex;
            }
          }
          .checkin {
            margin: 0.5rem;
          }
          .icon {
            font-size: 1.5rem;
          }
        }
      }
      

      .btn {
        width: 100%;
        padding: 0.6rem 1.5rem;
        color: var(--whiteColor);
        font-weight: 600;
        margin: 1rem 0 1rem 0;
      }
      .btn:hover {
        background: var(--HoverColor);
      }
      .secContainer {
        margin: 1rem 0rem 1rem 0rem;
        padding: 0;

        .secHeader {
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          .textDiv {
            .secTitle {
              color: var(--blackColor);
              line-height: 1.5rem;
              margin-bottom: 0;
              // padding-bottom: 0.5rem;
              font-weight: 600;
            }
          }
        }

        .title {
          color: var(--blackColor);
          line-height: 1.5rem;
          padding-bottom: 2rem;
          text-align: center;
          font-size: 2rem;
        }
      }

      .facilities {
        margin: 1rem 0 1rem 0;
        font-size: 18px;
      }

      padding: 0.5rem 0;
      gap: 1rem;

      h3 {
        margin: 0;
      }
      p {
        text-align: justify;
        margin-bottom: 0;
        font-size: 18px;
      }
      li {
        list-style-type: circle;
      }
    }
  }
}
