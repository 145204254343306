:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

.counter {
  display: flex;
  justify-content: space-evenly;
  .count {
    font-size: 40px;
    margin-top: 1.5rem;
    // position: relative;
    // z-index: -1;
  }

  .btnIncrement,
  .btnDecreament {
    // height: 50px;
    // width: 120px;
    // position: relative;
    padding: 0 40px;
    border-radius: 10px;
    font-size: 40px;
    background-color: var(--PrimaryColor);
    border: 2px solid #2e8d46;
    color: white;
    margin: 20px;
  }
}
