/* colors */
:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.about {
  .secContainer {
    .title {
      color: var(--blackColor);
      line-height: 1.5rem;
      padding-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
    }

    .mainContent {
      gap: 1rem;
      .singleItem {
        text-align: center;

        img {
          background: rgba(247, 144, 54, 0.265);
          width: 150px;
          border-radius: 60%;
          padding: 1rem;
        }
        h3 {
          font-weight: 700;
          color: var(--blackColor);
          padding: 0.5rem;
        }
        p {
          font-size: 13px;
          font-weight: 500;
          padding-bottom: 0.5rem;
          color: var(--textColor);
        }
      }
    }

    .videoCard {
      margin-top: 2rem !important;
      background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.73),
          rgba(0, 0, 0, 0.609)
        ),
        url("../../assets/map.webp");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .cardContent {
        gap: 2rem;
        padding: 2rem 1rem;

        .cardText {
          h2 {
            color: var(--whiteColor);
            font-weight: 600;
            padding-bottom: 1rem;
          }
          p {
            color: var(--whiteColor);
            opacity: 0.7;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
          }
        }
      }
      .cardVideo {
        border: 2px solid;
        border-color: var(--whiteColor);
        height: 200px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// media queries
@media screen and (min-width: 720px) {
  .about {
    .secContainer {
      .videoCard {
        .cardContent {
          gap: 4rem;
          padding: 4rem 3rem;

          .cardText {
            h2 {
              font-size: 2rem;
            }
            p {
              opacity: 0.7;
              font-size: 15px;
            }
          }
        }
        .cardContent {
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .about {
    .secContainer {
      .mainContent {
        padding-inline-start: 2.5rem 0;
      }
    }
  }
}
