@import url("https://unpkg.com/css.gg@2.0.0/icons/css/calendar.css");
/* colors */
:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.calendarWrap {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
}
.calendarWrap h4 {
  margin: 10px;
}
.calendarWrap .inputBox {
  font-size: 16px;
  padding: 5px 8px 4px 8px;
  border-radius: 3px;
  border: 1px solid #666;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  border: 1px solid #ccc;
  z-index: 999;
}/*# sourceMappingURL=dateRange.css.map */