:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

.forest .secContainer .fauna {
  padding-top: 3rem;
}
.forest .secContainer .fauna .secContainer {
  padding: 0;
}
.forest .secContainer .fauna .secContainer .secHeader {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.forest .secContainer .fauna .secContainer .secHeader .textDiv .secTitle {
  color: var(--blackColor);
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
}
.forest .secContainer .fauna .secContainer .secHeader .textDiv p {
  font-size: 13px;
  color: var(--textColor);
  font-weight: 500;
}
.forest .secContainer .fauna .secContainer .secHeader .iconsDiv {
  gap: 0.5rem;
}
.forest .secContainer .fauna .secContainer .secHeader .iconsDiv .icon {
  padding: 0.5rem;
  background: var(--greyText);
  border-radius: 50%;
  font-size: 2.5rem;
}
.forest .secContainer .fauna .secContainer .secHeader .iconsDiv .icon:hover {
  background: var(--blackColor);
  color: var(--whiteColor);
}
.forest .secContainer .fauna .secContainer .secHeader .iconsDiv .leftIcon {
  background: var(--blackColor);
  color: var(--whiteColor);
}
.forest .secContainer .fauna .secContainer .secHeader .iconsDiv .leftIcon:hover {
  background: var(--greyText);
  color: var(--blackColor);
}
.forest .secContainer .fauna .secContainer .mainContent {
  padding: 2rem 0;
  gap: 1rem;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination {
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage .overlayInfo {
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 0;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.846), rgba(0, 0, 0, 0.759));
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage .overlayInfo h3 {
  color: var(--whiteColor);
  font-weight: 600;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage .overlayInfo p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 13px;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage .overlayInfo .icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0.5rem;
  background: var(--PrimaryColor);
  font-size: 2.5rem;
  border-radius: 50%;
  color: var(--whiteColor);
  border: 1px solid transparent;
  transition: 0.3s ease;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destImage .overlayInfo .icon:hover {
  background: var(--blackColor);
  border: 1px solid var(--PrimaryColor);
  color: var(--PrimaryColor);
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter {
  padding: 1rem;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter .number {
  font-size: 2rem;
  font-weight: 700;
  color: var(--blackColor);
  position: relative;
  width: -moz-max-content;
  width: max-content;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter .number::after {
  content: "";
  position: absolute;
  background-color: var(--PrimaryColor);
  top: 45%;
  right: -150%;
  height: 5px;
  width: 45px;
  border-radius: 1rem;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter .destText {
  justify-content: space-between;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter .destText h6 {
  color: var(--blackColor);
  font-size: 15px;
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter .destText .flex {
  font-size: 16px;
  font-weight: 600;
  align-items: flex-start;
  transform: translateY(5px);
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination .destFooter .destText .flex .icon {
  color: var(--PrimaryColor);
}
.forest .secContainer .fauna .secContainer .mainContent .singleDestination:hover .overlayInfo {
  overflow: visible;
  padding: 1rem 1.5rem;
  height: 100%;
}
.forest .secContainer .articleFauna {
  padding-top: 1rem;
}
.forest .secContainer .articleFauna p {
  text-align: justify;
}
.forest .secContainer .articleFlora {
  padding-top: 1rem;
}
.forest .secContainer .articleFlora p {
  text-align: justify;
}
.forest .secContainer .title {
  color: var(--blackColor);
  line-height: 1.5rem;
  padding-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
}
.forest .secContainer .mainContent {
  gap: 1rem;
}
.forest .secContainer .mainContent .singleItem {
  text-align: center;
}
.forest .secContainer .mainContent .singleItem img {
  background: rgba(247, 144, 54, 0.265);
  width: 150px;
  border-radius: 60%;
  padding: 1rem;
}
.forest .secContainer .mainContent .singleItem h3 {
  font-weight: 700;
  color: var(--blackColor);
  padding: 0.5rem;
}
.forest .secContainer .mainContent .singleItem p {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 0.5rem;
  color: var(--textColor);
}
.forest .secContainer .videoCard-flora {
  margin-top: 2rem !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.609)), url("../../assets/flora.avif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.forest .secContainer .videoCard-flora .cardContent {
  gap: 2rem;
  padding: 2rem 1rem;
}
.forest .secContainer .videoCard-flora .cardContent .cardText h2 {
  color: var(--whiteColor);
  font-weight: 600;
  padding-bottom: 1rem;
}
.forest .secContainer .videoCard-flora .cardContent .cardText p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.forest .secContainer .videoCard-flora .cardVideo {
  border: 2px solid;
  border-color: var(--whiteColor);
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.forest .secContainer .videoCard-flora .cardVideo video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.forest .secContainer .videoCard-fauna {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.609)), url("../../assets/fauna.avif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.forest .secContainer .videoCard-fauna .cardContent {
  gap: 2rem;
  padding: 2rem 1rem;
}
.forest .secContainer .videoCard-fauna .cardContent .cardText h2 {
  color: var(--whiteColor);
  font-weight: 600;
  padding-bottom: 1rem;
}
.forest .secContainer .videoCard-fauna .cardContent .cardText p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.forest .secContainer .videoCard-fauna .cardVideo {
  border: 2px solid;
  border-color: var(--whiteColor);
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.forest .secContainer .videoCard-fauna .cardVideo video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 720px) {
  .forest {
    padding-bottom: 0;
  }
  .forest .secContainer .secHeader {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .forest .secContainer .secHeader .textDiv {
    text-align: start;
    max-width: 70%;
  }
  .forest .secContainer .secHeader .iconsDiv {
    justify-content: flex-end;
  }
  .forest .secContainer .videoCard-flora .cardContent {
    gap: 4rem;
    padding: 4rem 3rem;
  }
  .forest .secContainer .videoCard-flora .cardContent .cardText h2 {
    font-size: 2rem;
  }
  .forest .secContainer .videoCard-flora .cardContent .cardText p {
    opacity: 0.7;
    font-size: 15px;
  }
  .forest .secContainer .videoCard-flora .cardContent {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .forest .secContainer .videoCard-fauna .cardContent {
    gap: 4rem;
    padding: 4rem 3rem;
  }
  .forest .secContainer .videoCard-fauna .cardContent .cardText h2 {
    font-size: 2rem;
  }
  .forest .secContainer .videoCard-fauna .cardContent .cardText p {
    opacity: 0.7;
    font-size: 15px;
  }
  .forest .secContainer .videoCard-fauna .cardContent {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (min-width: 1024px) {
  .forest .secContainer .secHeader .textDiv .secTitle {
    font-size: 2.2rem;
  }
  .forest .secContainer .secHeader .textDiv p {
    font-size: 15px;
  }
  .forest .secContainer .mainContent {
    padding-inline-start: 2.5rem 0;
    grid-template-columns: repeat(4, 1fr);
  }
  .forest .secContainer .mainContentflora {
    grid-template-columns: repeat(2, 1fr);
  }
}/*# sourceMappingURL=hutanPage.css.map */