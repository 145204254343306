:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.189);
}

.offer {
  padding-top: 0;
  padding-bottom: 3rem;
}
.offer .secContainer {
  padding: 1rem;
}
.offer .secContainer .secIntro {
  margin: 0 auto 2rem;
}
.offer .secContainer .top {
  padding-top: 0rem;
}
.offer .secContainer .mainContent {
  gap: 1rem;
}
.offer .secContainer .mainContent .singleOffer {
  border-radius: 1rem;
  overflow: hidden;
  padding: 0.5rem;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.189);
}
.offer .secContainer .mainContent .singleOffer .icon {
  font-size: 20px;
  color: var(--blackColor);
}
.offer .secContainer .mainContent .singleOffer small {
  font-weight: 500;
  color: var(--textColor);
}
.offer .secContainer .mainContent .singleOffer .destImage {
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.offer .secContainer .mainContent .singleOffer .destImage img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.offer .secContainer .mainContent .singleOffer .destImage .discount {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background: var(--PrimaryColor);
  color: var(--whiteColor);
  border-radius: 3rem;
  font-size: 10px;
  font-weight: 500;
}
.offer .secContainer .mainContent .singleOffer .offerBody {
  padding: 1rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .price {
  justify-content: space-between;
}
.offer .secContainer .mainContent .singleOffer .offerBody .price .sided {
  text-align: left;
}
.offer .secContainer .mainContent .singleOffer .offerBody .price h3 {
  color: var(--blackColor);
  font-weight: 700;
  font-size: 1.3rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .price h4 {
  color: var(--blackColor);
  font-weight: 700;
  font-size: 1.1rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .price .status {
  padding: 5px 10px;
  color: var(--HoverColor);
  background-color: var(--greyBg);
  font-size: 10px;
  font-weight: 600;
  border-radius: 3rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .amenities {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem 0;
  gap: 0.5rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .amenities .singleAmenity {
  padding: 5px 10px;
  border-radius: 5px;
  background: var(--greyBg);
  gap: 0.5rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .btn {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 0.6rem;
  color: var(--whiteColor);
  font-weight: 600;
  gap: 0.5rem;
}
.offer .secContainer .mainContent .singleOffer .offerBody .btn .icon {
  color: var(--whiteColor);
}
.offer .secContainer .mainContent .singleOffer .offerBody .btn:hover {
  background: var(--HoverColor);
}
.offer .secContainer .mainContent .singleOffer:hover {
  transform: translateY(-7px);
}/*# sourceMappingURL=forestPackage.css.map */