:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.ticket .btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.ticket .btn:hover {
  background: var(--HoverColor);
}
.ticket .ticketMaxWidth {
  max-width: 500px;
  margin: 0 auto;
}
.ticket .ticketMaxWidth .ticket-detail {
  margin-top: 0;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--greyBg);
}
.ticket .ticketMaxWidth .ticket-detail .ticketCounter {
  display: flex;
  justify-content: space-evenly;
}
.ticket .ticketMaxWidth .ticket-detail .ticketCounter .ticket {
  font-size: 40px;
  margin-top: 0.5rem;
}
.ticket .ticketMaxWidth .ticket-detail .ticketCounter .btnIncrement,
.ticket .ticketMaxWidth .ticket-detail .ticketCounter .btnDecreament {
  padding: 0 40px;
  border-radius: 10px;
  font-size: 40px;
  background-color: var(--PrimaryColor);
  border: 2px solid #2e8d46;
  color: white;
  margin: 20px;
}
.ticket .ticketMaxWidth .ticket-detail .no-va {
  margin: 1rem;
}
.ticket .ticketMaxWidth .ticket-detail span {
  font-size: 20px;
  padding: 1rem;
}
.ticket .ticketMaxWidth .ticket-detail img {
  width: 5rem;
}/*# sourceMappingURL=Ticket.css.map */