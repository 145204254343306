:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

.forest {
  .secContainer {
    .fauna {
      padding-top: 3rem;
      .secContainer {
        padding: 0;
        .secHeader {
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;

          .textDiv {
            .secTitle {
              color: var(--blackColor);
              line-height: 1.5rem;
              padding-bottom: 0.5rem;
              font-weight: 600;
            }

            p {
              font-size: 13px;
              color: var(--textColor);
              font-weight: 500;
            }
          }
          .iconsDiv {
            gap: 0.5rem;
            .icon {
              padding: 0.5rem;
              background: var(--greyText);
              border-radius: 50%;
              font-size: 2.5rem;
              &:hover {
                background: var(--blackColor);
                color: var(--whiteColor);
              }
            }

            .leftIcon {
              background: var(--blackColor);
              color: var(--whiteColor);
              &:hover {
                background: var(--greyText);
                color: var(--blackColor);
              }
            }
          }
        }

        .mainContent {
          padding: 2rem 0;
          gap: 1rem;

          .singleDestination {
            overflow: hidden;
            border-radius: 1rem;
            box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);

            .destImage {
              height: 200px;
              width: 100%;
              position: relative;
              overflow: hidden;
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
              .overlayInfo {
                position: absolute;
                display: flex;
                align-items: flex-start;
                padding: 0;
                // padding: 1rem 1.5rem;
                justify-content: center;
                flex-direction: column;
                background: linear-gradient(
                  rgba(0, 0, 0, 0.846),
                  rgba(0, 0, 0, 0.759)
                );
                bottom: 0;
                width: 100%;
                // height: 100%;
                height: 0;
                overflow: hidden;

                h3 {
                  color: var(--whiteColor);
                  font-weight: 600;
                }
                p {
                  color: var(--whiteColor);
                  opacity: 0.7;
                  font-size: 13px;
                }
                .icon {
                  position: absolute;
                  right: 10px;
                  bottom: 10px;
                  padding: 0.5rem;
                  background: var(--PrimaryColor);
                  font-size: 2.5rem;
                  border-radius: 50%;
                  color: var(--whiteColor);
                  border: 1px solid transparent;
                  transition: 0.3s ease;

                  &:hover {
                    background: var(--blackColor);
                    border: 1px solid var(--PrimaryColor);
                    color: var(--PrimaryColor);
                  }
                }
              }
            }

            .destFooter {
              padding: 1rem;

              .number {
                font-size: 2rem;
                font-weight: 700;
                color: var(--blackColor);
                position: relative;
                width: max-content;
                &::after {
                  content: "";
                  position: absolute;
                  background-color: var(--PrimaryColor);
                  top: 45%;
                  right: -150%;
                  height: 5px;
                  width: 45px;
                  border-radius: 1rem;
                }
              }
              .destText {
                justify-content: space-between;
                h6 {
                  color: var(--blackColor);
                  font-size: 15px;
                }
                .flex {
                  font-size: 16px;
                  font-weight: 600;
                  align-items: flex-start;
                  transform: translateY(5px);

                  .icon {
                    color: var(--PrimaryColor);
                  }
                }
              }
            }

            &:hover {
              .overlayInfo {
                overflow: visible;
                padding: 1rem 1.5rem;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .articleFauna {
      padding-top: 1rem;
      p {
        text-align: justify;
      }
    }

    .articleFlora {
      padding-top: 1rem;
      p {
        text-align: justify;
      }
    }
    .title {
      color: var(--blackColor);
      line-height: 1.5rem;
      padding-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
    }

    .mainContent {
      gap: 1rem;
      .singleItem {
        text-align: center;

        img {
          background: rgba(247, 144, 54, 0.265);
          width: 150px;
          border-radius: 60%;
          padding: 1rem;
        }
        h3 {
          font-weight: 700;
          color: var(--blackColor);
          padding: 0.5rem;
        }
        p {
          font-size: 13px;
          font-weight: 500;
          padding-bottom: 0.5rem;
          color: var(--textColor);
        }
      }
    }

    .videoCard-flora {
      margin-top: 2rem !important;
      background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.73),
          rgba(0, 0, 0, 0.609)
        ),
        url("../../assets/flora.avif");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .cardContent {
        gap: 2rem;
        padding: 2rem 1rem;

        .cardText {
          h2 {
            color: var(--whiteColor);
            font-weight: 600;
            padding-bottom: 1rem;
          }
          p {
            color: var(--whiteColor);
            opacity: 0.7;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
          }
        }
      }
      .cardVideo {
        border: 2px solid;
        border-color: var(--whiteColor);
        height: 200px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .videoCard-fauna {
      // margin-top: 2rem !important;
      background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.73),
          rgba(0, 0, 0, 0.609)
        ),
        url("../../assets/fauna.avif");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .cardContent {
        gap: 2rem;
        padding: 2rem 1rem;

        .cardText {
          h2 {
            color: var(--whiteColor);
            font-weight: 600;
            padding-bottom: 1rem;
          }
          p {
            color: var(--whiteColor);
            opacity: 0.7;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
          }
        }
      }
      .cardVideo {
        border: 2px solid;
        border-color: var(--whiteColor);
        height: 200px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// media queries
@media screen and (min-width: 720px) {
  .forest {
    padding-bottom: 0;
    .secContainer {
      .secHeader {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .textDiv {
          text-align: start;
          max-width: 70%;
        }

        .iconsDiv {
          justify-content: flex-end;
        }
      }
      .videoCard-flora {
        .cardContent {
          gap: 4rem;
          padding: 4rem 3rem;

          .cardText {
            h2 {
              font-size: 2rem;
            }
            p {
              opacity: 0.7;
              font-size: 15px;
            }
          }
        }
        .cardContent {
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }

      .videoCard-fauna {
        .cardContent {
          gap: 4rem;
          padding: 4rem 3rem;

          .cardText {
            h2 {
              font-size: 2rem;
            }
            p {
              opacity: 0.7;
              font-size: 15px;
            }
          }
        }
        .cardContent {
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .forest {
    .secContainer {
      .secHeader {
        .textDiv {
          .secTitle {
            font-size: 2.2rem;
          }
          p {
            font-size: 15px;
          }
        }
      }
      .mainContent {
        padding-inline-start: 2.5rem 0;
        grid-template-columns: repeat(4, 1fr);
      }
      .mainContentflora{
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
