/* colors */
:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.footer {
  background: var(--greyBg);
  padding: 2rem 0;
}
.footer .secContainer {
  gap: 1.5rem;
  text-align: center;
}
.footer .secContainer .footerLogo {
  padding-bottom: 0.5rem;
}
.footer .secContainer .footerLogo .logoIcon {
  color: var(--blackColor);
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.footer .secContainer .footerLogo .logoIcon .icon {
  font-size: 25px;
  color: var(--PrimaryColor);
  margin-right: 0.5rem;
}
.footer .secContainer .socials {
  gap: 0.5rem;
  margin: auto;
  justify-content: center;
}
.footer .secContainer .socials .icon {
  font-size: 30px;
  background: var(--PrimaryColor);
  border-radius: 50%;
  padding: 8px;
  color: var(--whiteColor);
}
.footer .secContainer .socials .icon:hover {
  background: var(--blackColor);
}
.footer .secContainer .footerLinks {
  display: grid;
  row-gap: 0.2rem;
}
.footer .secContainer .footerLinks .linkTitle {
  padding-bottom: 0.5rem;
  color: var(--blackColor);
  font-weight: 700;
}
.footer .secContainer .footerLinks li:hover {
  transform: translateX(10px);
}
.footer .secContainer .footerLinks li:hover a {
  color: var(--PrimaryColor);
}
.footer .secContainer .footerLinks a,
.footer .secContainer .footerLinks .phone,
.footer .secContainer .footerLinks .email {
  color: var(--textColor);
  font-size: 13px;
  font-weight: 500;
}
.footer .secContainer h6 {
  padding: 1rem;
}

@media screen and (min-width: 448px) {
  .secContainer {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    align-items: flex-start;
  }
  .secContainer .footerLinks {
    justify-content: center;
  }
}
@media screen and (min-width: 640px) {
  .secContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 900px) {
  .secContainer {
    padding: 2rem;
  }
}/*# sourceMappingURL=footer.css.map */