:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}
.register {
  .registerCard {
    .logoDiv {
      .logo {
        color: var(--blackColor);
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        justify-content: center;
      }
      .icon {
        color: var(--PrimaryColor);
        font-size: 25px;
        margin-right: 10px;
      }
      .iconLogo {
        width: 3rem;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    margin-top: 2rem !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .cardContent {
      gap: 2rem;
      padding: 2rem 1rem;

      .cardText {
        h2 {
          color: var(--blackColor);
          font-weight: 600;
          padding-bottom: 1rem;
        }
        p {
          color: var(--blackColor);
          opacity: 0.7;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }

      .cardRegister {
        .emailDiv,
        .passwordDiv,
        .nameDiv {
          width: 100%;
          label {
            display: block;
            color: var(--textColor);
            font-weight: 500;
            font-size: 13px;
            padding-bottom: 0.4rem;
          }

          input {
            width: 100%;
            background: var(--inputColor);
            border-radius: 10px;
            border: none;
            padding: 10px;
            font-size: 13px;
            font-weight: 500;

            &::placeholder {
              font-size: 13px;
              opacity: 0.5;
              font-weight: 400;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
      .btn {
        width: 100%;
        padding: 0.6rem 1.5rem;
        color: var(--whiteColor);
        font-weight: 600;
      }
      .btn:hover {
        background: var(--HoverColor);
      }
    }
    .cardLogin {
      border: 2px solid;
      border-color: var(--whiteColor);
      height: 100%;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
