:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /* --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.045); */
}

.container .articleMountain p {
  text-align: justify;
}
.container .mainContentGunung {
  padding: 2rem 0;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 4rem;
}
.container .mainContentGunung .singleDestination {
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);
}
.container .mainContentGunung .singleDestination .destImage {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.container .mainContentGunung .singleDestination .destImage img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.container .mainContentGunung .singleDestination .destImage .overlayInfo {
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 0;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.846), rgba(0, 0, 0, 0.759));
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.container .mainContentGunung .singleDestination .destImage .overlayInfo h3 {
  color: var(--whiteColor);
  font-weight: 600;
}
.container .mainContentGunung .singleDestination .destImage .overlayInfo p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 13px;
}
.container .mainContentGunung .singleDestination .destImage .overlayInfo .icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0.5rem;
  background: var(--PrimaryColor);
  font-size: 2.5rem;
  border-radius: 50%;
  color: var(--whiteColor);
  border: 1px solid transparent;
  transition: 0.3s ease;
}
.container .mainContentGunung .singleDestination .destImage .overlayInfo .icon:hover {
  background: var(--blackColor);
  border: 1px solid var(--PrimaryColor);
  color: var(--PrimaryColor);
}
.container .mainContentGunung .singleDestination .destFooter {
  padding: 1rem;
}
.container .mainContentGunung .singleDestination .destFooter .number {
  font-size: 2rem;
  font-weight: 700;
  color: var(--blackColor);
  position: relative;
  width: -moz-max-content;
  width: max-content;
}
.container .mainContentGunung .singleDestination .destFooter .number::after {
  content: "";
  position: absolute;
  background-color: var(--PrimaryColor);
  top: 45%;
  right: -150%;
  height: 5px;
  width: 45px;
  border-radius: 1rem;
}
.container .mainContentGunung .singleDestination .destFooter .destText {
  justify-content: space-between;
}
.container .mainContentGunung .singleDestination .destFooter .destText h6 {
  color: var(--blackColor);
  font-size: 15px;
}
.container .mainContentGunung .singleDestination .destFooter .destText .flex {
  font-size: 16px;
  font-weight: 600;
  align-items: flex-start;
  transform: translateY(5px);
}
.container .mainContentGunung .singleDestination .destFooter .destText .flex .icon {
  color: var(--PrimaryColor);
}
.container .mainContentGunung .singleDestination:hover .overlayInfo {
  overflow: visible;
  padding: 1rem 1.5rem;
  height: 100%;
}

@media screen and (min-width: 720px) {
  .container .mainContentGunung {
    padding-inline-start: 2.5rem 0;
    grid-template-columns: repeat(4, 1fr);
  }
  .secContainer .secHeader {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .secContainer .secHeader .textDiv {
    text-align: start;
    max-width: 70%;
  }
  .secContainer .secHeader .iconsDiv {
    justify-content: flex-end;
  }
  .secContainer .videoCard-flora .cardContent {
    gap: 4rem;
    padding: 4rem 3rem;
  }
  .secContainer .videoCard-flora .cardContent .cardText h2 {
    font-size: 2rem;
  }
  .secContainer .videoCard-flora .cardContent .cardText p {
    opacity: 0.7;
    font-size: 15px;
  }
  .secContainer .videoCard-flora .cardContent {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .secContainer .videoCard-fauna .cardContent {
    gap: 4rem;
    padding: 4rem 3rem;
  }
  .secContainer .videoCard-fauna .cardContent .cardText h2 {
    font-size: 2rem;
  }
  .secContainer .videoCard-fauna .cardContent .cardText p {
    opacity: 0.7;
    font-size: 15px;
  }
  .secContainer .videoCard-fauna .cardContent {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media screen and (min-width: 1024px) {
  .container .mainContentGunung {
    padding-inline-start: 2.5rem 0;
    grid-template-columns: repeat(4, 1fr);
  }
}/*# sourceMappingURL=gunungPage.css.map */