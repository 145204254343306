:root {
  --PrimaryColor: hsl(141, 100%, 27%);
  --HoverColor: hsl(128, 100%, 15%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

.register .registerCard {
  margin-top: 2rem !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.register .registerCard .logoDiv .logo {
  color: var(--blackColor);
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.register .registerCard .logoDiv .icon {
  color: var(--PrimaryColor);
  font-size: 25px;
  margin-right: 10px;
}
.register .registerCard .logoDiv .iconLogo {
  width: 3rem;
  cursor: pointer;
  margin-right: 10px;
}
.register .registerCard .cardContent {
  gap: 2rem;
  padding: 2rem 1rem;
}
.register .registerCard .cardContent .cardText h2 {
  color: var(--blackColor);
  font-weight: 600;
  padding-bottom: 1rem;
}
.register .registerCard .cardContent .cardText p {
  color: var(--blackColor);
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.register .registerCard .cardContent .cardRegister .emailDiv,
.register .registerCard .cardContent .cardRegister .passwordDiv,
.register .registerCard .cardContent .cardRegister .nameDiv {
  width: 100%;
}
.register .registerCard .cardContent .cardRegister .emailDiv label,
.register .registerCard .cardContent .cardRegister .passwordDiv label,
.register .registerCard .cardContent .cardRegister .nameDiv label {
  display: block;
  color: var(--textColor);
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 0.4rem;
}
.register .registerCard .cardContent .cardRegister .emailDiv input,
.register .registerCard .cardContent .cardRegister .passwordDiv input,
.register .registerCard .cardContent .cardRegister .nameDiv input {
  width: 100%;
  background: var(--inputColor);
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
}
.register .registerCard .cardContent .cardRegister .emailDiv input::-moz-placeholder, .register .registerCard .cardContent .cardRegister .passwordDiv input::-moz-placeholder, .register .registerCard .cardContent .cardRegister .nameDiv input::-moz-placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.register .registerCard .cardContent .cardRegister .emailDiv input::placeholder,
.register .registerCard .cardContent .cardRegister .passwordDiv input::placeholder,
.register .registerCard .cardContent .cardRegister .nameDiv input::placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.register .registerCard .cardContent .cardRegister .emailDiv input:focus,
.register .registerCard .cardContent .cardRegister .passwordDiv input:focus,
.register .registerCard .cardContent .cardRegister .nameDiv input:focus {
  outline: none;
}
.register .registerCard .cardContent .btn {
  width: 100%;
  padding: 0.6rem 1.5rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.register .registerCard .cardContent .btn:hover {
  background: var(--HoverColor);
}
.register .registerCard .cardLogin {
  border: 2px solid;
  border-color: var(--whiteColor);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}/*# sourceMappingURL=register.css.map */